import { configureStore } from '@reduxjs/toolkit';
// reducers
import appReducer from '../features/appSlice';
import regionReducer from '../features/regionSlice';
import departmentReducer from '../features/departmentSlice';
import positionReducer from '../features/positionSlice';
import employeeReducer from '../features/employeeSlice';
// services
import { appApi } from '../services/app.service';
import { regionApi } from '../services/region.service';
import { departmentApi } from '../services/department.service';
import { positionApi } from '../services/position.service';
import { employeeApi } from '../services/employee.service';

export const store = configureStore({
  reducer: {
    app: appReducer,
    regions: regionReducer,
    departments: departmentReducer,
    positions: positionReducer,
    employees: employeeReducer,
    [appApi.reducerPath]: appApi.reducer,
    [regionApi.reducerPath]: regionApi.reducer,
    [departmentApi.reducerPath]: departmentApi.reducer,
    [positionApi.reducerPath]: positionApi.reducer,
    [employeeApi.reducerPath]: employeeApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(appApi.middleware)
      .concat(regionApi.middleware)
      .concat(departmentApi.middleware)
      .concat(positionApi.middleware)
      .concat(employeeApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
