import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PositionData } from '../store/types';

interface PositionState {
  editedPosition: PositionData | null;
  positions: PositionData[];
}

const initialState: PositionState = {
  editedPosition: null,
  positions: []
};

export const positionSlice = createSlice({
  name: 'positions',
  initialState,
  reducers: {
    setEditedPosition: (state, action: PayloadAction<PositionData | null>) => {
      state.editedPosition = action.payload;
    },
    resetEditedPosition: (state) => {
      state.editedPosition = null;
    },
    setPositions: (state, action: PayloadAction<PositionData[]>) => {
      state.positions = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedPosition, setPositions, resetEditedPosition } = positionSlice.actions;

export default positionSlice.reducer;
