import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RegionData } from '../store/types';

interface RegionState {
  editedRegion: RegionData | null;
  regions: RegionData[];
}

const initialState: RegionState = {
  editedRegion: null,
  regions: []
};

export const regionSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {
    setEditedRegion: (state, action: PayloadAction<RegionData | null>) => {
      state.editedRegion = action.payload;
    },
    resetEditedRegion: (state) => {
      state.editedRegion = null;
    },
    setRegions: (state, action: PayloadAction<RegionData[]>) => {
      state.regions = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedRegion, setRegions, resetEditedRegion } = regionSlice.actions;

export default regionSlice.reducer;
