import React, { useState } from 'react';
import Avatar from 'react-avatar-edit';
import 'cropperjs/dist/cropper.css';
import { useUploadFilesMutation } from '../../services/file.service';

const IMAGE_FILE_WEIGHT_LIMIT = 512000; // 512kb
interface UploadAvatarType {
  imagePath?: string;
  setValue: Function;
  fieldName: string;
}

function CropperComponent({ imagePath, setValue, fieldName }: UploadAvatarType) {
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>(null);
  const [uploadErrorMessage, setUploadErrorMessage] = useState<string>('');
  const [uploadFiles, { isLoading }] = useUploadFilesMutation();

  const onClose = () => {
    setPreview(null);
    setUploadErrorMessage('');
  };

  const onCrop = (preview: any) => {
    setUploadErrorMessage('');
    setPreview(preview);
  };

  const onBeforeFileLoad = (elem: any) => {
    if (elem.target.files[0].size > IMAGE_FILE_WEIGHT_LIMIT) {
      setUploadErrorMessage('Zdjęcie jest za duże, maksymalny rozmiar to 500kb');
      elem.target.value = '';
    }
  };

  const saveImage = async (): Promise<void> => {
    if (preview) {
      const formData = new FormData();
      formData.append('files', preview);

      const data = await uploadFiles(formData).unwrap();

      if (data && data.filePath) {
        setValue(fieldName, data.filePath);
        setShowCropper(false);
      }
    }
  };

  return (
    <div className="cropper">
      <button onClick={() => setShowCropper(true)} type="button" className="submit-button btn btn-primary">
        Wybierz zdjęcie
      </button>
      {showCropper && (
        <div className="cropper-wrapper">
          <div className="cropper-content p-3 d-flex flex-column justify-content-between">
            <h5>Dostosuj wybrane zdjęcie</h5>
            {isLoading && (
              <div>
                <span>trwa dodawanie zdjęć</span>
              </div>
            )}
            {!isLoading && (
              <div className="avatar-view mb-3">
                <Avatar
                  width={366}
                  label="Wybierz zdjęcie"
                  height={200}
                  onCrop={onCrop}
                  onClose={onClose}
                  onBeforeFileLoad={onBeforeFileLoad}
                  src={''}
                />
                {uploadErrorMessage && <div className="error-message">{uploadErrorMessage}</div>}
              </div>
            )}
            <div className="cropper-buttons-warapper d-flex justify-content-end">
              <button type="button" onClick={() => setShowCropper(false)} className="cancel-button btn btn-secondary m-0">
                Anuluj
              </button>
              {preview && (
                <button type="button" onClick={saveImage} disabled={isLoading} className="ml-2 submit-button btn btn-primary m-0">
                  Zapisz zdjęcie
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CropperComponent;
