import React, { useEffect } from 'react';
import { DepartmentData, EmployeeData, FilterData, PositionData, RegionData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { useGetEmployeesQuery } from '../../services/employee.service';
import { setAdminEmployees, setEditedEmployee } from '../../features/employeeSlice';
import EmployeeFormComponent from './employeeForm.component';
import _ from 'lodash';
import AvatarComponent from '../application/avatar.component';
import FiltersComponent, { FilterEmployeesType } from '../application/filters.component';

function AdminEmployeesComponent() {
  const dispatch = useAppDispatch();

  const allEmployees = useAppSelector((state) => state.employees.adminEmployees);
  const allPositions = useAppSelector((state) => state.positions.positions);
  const allDepartments = useAppSelector((state) => state.departments.departments);
  const allRegions = useAppSelector((state) => state.regions.regions);

  const [filterData, setFilterData] = React.useState<FilterData>();
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addEmployeeInputRef = React.useRef<any>(null);
  const { data: employeeData } = useGetEmployeesQuery();

  useEffect(() => {
    if (employeeData && _.get(employeeData, 'documents', []).length) {
      dispatch(setAdminEmployees(employeeData.documents));
    } else {
      dispatch(setAdminEmployees([]));
    }
  }, [employeeData]);

  const checkIfEmployeeAlreadyExist = (employeeName: string) => {
    if (employeeData && !_.isEmpty(employeeData.documents)) {
      let allEmployees: EmployeeData[] = employeeData.documents;
      return !!allEmployees.find((employee: EmployeeData) => `${employee.firstName} ${employee.lastName}` === employeeName);
    }
    return false;
  };

  const handleEditEmployee = (employeeId: number) => {
    if (employeeData && _.get(employeeData, 'documents', []).length) {
      const editedEmployee: EmployeeData | undefined = employeeData.documents.find((employee: EmployeeData) => employee.id === employeeId);

      if (editedEmployee) {
        dispatch(setEditedEmployee(editedEmployee));

        if (addEmployeeInputRef.current && !isCollapsing) {
          addEmployeeInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const handleFilter = (filterData: FilterData): void => {
    setFilterData(filterData);
  };

  const renderEmployeeList = () => {
    if (employeeData && !_.isEmpty(employeeData.documents)) {
      let employees: EmployeeData[] = employeeData.documents;

      const isFiltered = filterData && !Object.values(filterData).some((x) => x !== null && x !== '');

      if (isFiltered !== undefined && filterData) {
        employees = allEmployees.filter((employee: EmployeeData) => {
          let findedByQuery = false;
          let findedByPosition = true;
          let findedBySupervisor = true;
          let findedByDepartment = true;
          let findedByRegion = true;

          // BY OPTIONAL FIELDS

          if (filterData.departmentId && employee.departmentId.toString() !== filterData.departmentId) {
            findedByPosition = false;
          }

          if (filterData.positionId && employee.positionId.toString() !== filterData.positionId) {
            findedBySupervisor = false;
          }

          if (filterData.supervisorId && employee.supervisorId.toString() !== filterData.supervisorId) {
            findedByDepartment = false;
          }

          if (filterData.regionId && employee.regionId.toString() !== filterData.regionId) {
            findedByRegion = false;
          }

          // BY QUERY VALUE
          if (filterData.query && employee.firstName.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.lastName.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.email.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.phone.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (!filterData.query) {
            findedByQuery = true;
          }

          return findedByQuery && findedByPosition && findedBySupervisor && findedByDepartment && findedByRegion;
        });
      }

      if (!employees.length) {
        return (
          <tr>
            <td align="center" colSpan={11}>
              brak pracowników do wyświetlenia
            </td>
          </tr>
        );
      }

      return employees.map((employee: EmployeeData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
        const isInvisibleClass = !employee.isVisible ? 'is-invisible' : '';

        let positionName = '-';
        let supervisorName = '-';
        let departmentName = '-';
        let regionName = '-';

        if (employee.positionId && allPositions && allPositions.length) {
          const findedPosition: PositionData | undefined = allPositions.find((position: PositionData) => position.id === employee.positionId);
          if (findedPosition && findedPosition.name) {
            positionName = findedPosition.name;
          }
        }

        if (employee.supervisorId && allEmployees && allEmployees.length) {
          const findedEmployee: EmployeeData | undefined = allEmployees.find((supervisor: EmployeeData) => supervisor.id === employee.supervisorId);
          if (findedEmployee) {
            supervisorName = `${findedEmployee.firstName} ${findedEmployee.lastName}`;
          }
        }

        if (employee.departmentId && allDepartments && allDepartments.length) {
          const findedDepartment: DepartmentData | undefined = allDepartments.find(
            (department: DepartmentData) => department.id === employee.departmentId
          );
          if (findedDepartment) {
            departmentName = findedDepartment.name;
          }
        }

        if (employee.regionId && allRegions && allRegions.length) {
          const findedRegion: RegionData | undefined = allRegions.find((region: RegionData) => region.id === employee.regionId);
          if (findedRegion && findedRegion.name) {
            regionName = findedRegion.name;
          }
        }

        return (
          <tr className={`${rowClass} ${isInvisibleClass}`} key={`single-employee-row-${index}`}>
            <td>{index + 1}</td>
            <td>
              <AvatarComponent imagePath={employee.photo} width={50} height={50} />
            </td>
            <td valign="middle">{employee.firstName}</td>
            <td>{employee.lastName}</td>
            <td>{positionName}</td>
            <td>{supervisorName}</td>
            <td>{departmentName}</td>
            {/* <td>{regionName}</td> */}
            <td>{employee.email}</td>
            <td>{employee.phone}</td>
            <td>
              <div onClick={() => employee.id && handleEditEmployee(employee.id)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={11}>
          brak pracowników do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <EmployeeFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addEmployeeInputRef={addEmployeeInputRef}
        checkIfEmployeeAlreadyExist={checkIfEmployeeAlreadyExist}
      />
      <FiltersComponent handleFilter={handleFilter} employeesType={FilterEmployeesType.ADMIN_EMPLOYEES} />
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Zdjęcie</th>
            <th scope="col">Imię</th>
            <th scope="col">Nazwisko</th>
            <th scope="col">Stanowisko</th>
            <th scope="col">Przełożony</th>
            <th scope="col">Dział</th>
            {/* <th scope="col">Region</th> */}
            <th scope="col">E-mail</th>
            <th scope="col">Telefon</th>
            <th scope="col" style={{ width: '50px' }}>
              Akcja
            </th>
          </tr>
        </thead>
        <tbody>{renderEmployeeList()}</tbody>
      </table>
    </>
  );
}

export default AdminEmployeesComponent;
