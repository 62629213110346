import * as React from 'react';
import ToastComponent from '../application/toast.component';
import EmployeesComponent from '../employees/employees.component';
import PositionsComponent from './positions.component';
import DepartmentsComponent from './departments.component';
import RegionsComponent from './regions.component';
import { useGetPositionsQuery } from '../../services/position.service';
import { useGetDepartmentsQuery } from '../../services/department.service';
import { useGetRegionsQuery } from '../../services/region.service';
import AdminEmployeesComponent from './employees.component';

function AdministrationComponent() {
  const { refetch: refetchPositions } = useGetPositionsQuery();
  const { refetch: refetchDepartments } = useGetDepartmentsQuery();
  const { refetch: refetchRegions } = useGetRegionsQuery();

  const handleRefetchData = (type: string) => {
    switch (type) {
      case 'employess':
        // @TODO
        break;
      case 'positions':
        refetchPositions();
        break;
      case 'departments':
        refetchDepartments();
        break;
      case 'regions':
        refetchRegions();
        break;
    }
  };

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" onClick={() => handleRefetchData('employess')} role="presentation">
          <a
            className="nav-link active"
            id="employess-tab"
            data-toggle="tab"
            href="#employess"
            role="tab"
            aria-controls="employess"
            aria-selected="true">
            Użytkownicy
          </a>
        </li>
        <li className="nav-item" onClick={() => handleRefetchData('positions')} role="presentation">
          <a className="nav-link" id="positions-tab" data-toggle="tab" href="#positions" role="tab" aria-controls="positions" aria-selected="false">
            Stanowiska
          </a>
        </li>
        <li className="nav-item" onClick={() => handleRefetchData('departments')} role="presentation">
          <a
            className="nav-link"
            id="departments-tab"
            data-toggle="tab"
            href="#departments"
            role="tab"
            aria-controls="departments"
            aria-selected="false">
            Działy
          </a>
        </li>
        {/* <li className="nav-item" onClick={() => handleRefetchData('regions')} role="presentation">
          <a className="nav-link" id="regions-tab" data-toggle="tab" href="#regions" role="tab" aria-controls="regions" aria-selected="false">
            Regiony
          </a>
        </li> */}
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="employess" role="tabpanel" aria-labelledby="employess-tab">
          <AdminEmployeesComponent />
        </div>
        <div className="tab-pane fade" id="positions" role="tabpanel" aria-labelledby="positions-tab">
          <PositionsComponent />
        </div>
        <div className="tab-pane fade" id="departments" role="tabpanel" aria-labelledby="departments-tab">
          <DepartmentsComponent />
        </div>
        {/* <div className="tab-pane fade" id="regions" role="tabpanel" aria-labelledby="regions-tab">
          <RegionsComponent />
        </div> */}
      </div>
      <ToastComponent />
    </>
  );
}

export default AdministrationComponent;
