import * as React from 'react';
import './app.style.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { setUserID, setLoggedUser } from './features/appSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { useLazyGetLoggedUserQuery } from './services/app.service';
import AdministrationComponent from './components/administration/administration.component';
import EmployeesComponent from './components/employees/employees.component';
import HomeComponent from './components/application/home.component';
import ToastComponent from './components/application/toast.component';
import AppMaskComponent from './components/application/mask.component';
import PopupComponent from './components/application/popup.component';

const mainAppRoute = '';

function App() {
  const dispatch = useAppDispatch();
  const [getLoggedUser, loggedUserData] = useLazyGetLoggedUserQuery();

  // SET LOGGED USER ID FROM WORDPRESS
  React.useEffect(() => {
    // const userInputID = document.getElementById('wordpressUserID') as HTMLInputElement;
    // if (userInputID && userInputID.value) {
    //   dispatch(setUserID(userInputID.value));
    // }
    dispatch(setUserID('371'));
  }, []);

  // GET LOGGED USER BY USER ID FROM WORDPRESS
  React.useEffect(() => {
    getLoggedUser();
  }, []);

  // SET LOGGED USER TO STATE
  React.useEffect(() => {
    if (loggedUserData.data) {
      dispatch(setLoggedUser(loggedUserData.data.loggedUser));
    }
  }, [loggedUserData.data]);

  // SHOW MASK IF DATA IS FETCH
  const showMask = () => {
    if ((!loggedUserData.isFetching && !loggedUserData.isSuccess) || loggedUserData.isFetching) {
      return true;
    }
    return false;
  };

  return (
    <div className="employee-container container">
      {showMask() && <AppMaskComponent />}
      {!showMask() && (
        <Routes>
          <Route path={`/`} element={<HomeComponent />} />
          <Route
            path={`${mainAppRoute}/employees`}
            element={
              <RequireUserId>
                <EmployeesComponent />
              </RequireUserId>
            }
          />
          <Route
            path={`${mainAppRoute}/administration`}
            element={
              <RequireUserId>
                <AdministrationComponent />
              </RequireUserId>
            }
          />
          <Route path="*" element={<NotFoundComponent />} />
        </Routes>
      )}
      <ToastComponent />
      <PopupComponent />
      <div className="app-version">version 1.0.1</div>
    </div>
  );
}

function RequireUserId({ children }: { children: JSX.Element }) {
  const userID = useAppSelector((state) => state.app.userID);
  const location = useLocation();

  if (!userID) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

function NotFoundComponent() {
  return (
    <>
      <h2>nNie znaleziono takiego adresu.</h2>
    </>
  );
}

export default App;
