import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeData } from '../store/types';

interface EmployeeState {
  editedEmployee: EmployeeData | null;
  adminEmployees: EmployeeData[];
  userEmployees: EmployeeData[];
}

const initialState: EmployeeState = {
  editedEmployee: null,
  adminEmployees: [],
  userEmployees: []
};

export const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEditedEmployee: (state, action: PayloadAction<EmployeeData | null>) => {
      state.editedEmployee = action.payload;
    },
    resetEditedEmployee: (state) => {
      state.editedEmployee = null;
    },
    setAdminEmployees: (state, action: PayloadAction<EmployeeData[]>) => {
      state.adminEmployees = action.payload;
    },
    setUserEmployees: (state, action: PayloadAction<EmployeeData[]>) => {
      state.userEmployees = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedEmployee, setAdminEmployees, setUserEmployees, resetEditedEmployee } = employeeSlice.actions;

export default employeeSlice.reducer;
