import * as yup from 'yup';

export const searchInitialState = {
  query: '',
  positionId: '',
  supervisorId: '',
  departmentId: '',
  regionId: '',
};

export const searchSchema = yup.object().shape({
  query: yup.string(),
  positionId: yup.string(),
  supervisorId: yup.string(),
  departmentId: yup.string(),
  regionId: yup.string(),
});
