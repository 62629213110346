import React, { useEffect } from 'react';
import { DepartmentData, EmployeeData, FilterData, PositionData, RegionData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useGetEmployeesQuery } from '../../services/employee.service';
import { setUserEmployees } from '../../features/employeeSlice';
import _ from 'lodash';
import AvatarComponent from '../application/avatar.component';
import FiltersComponent, { FilterEmployeesType } from '../application/filters.component';
import { useGetPositionsQuery } from '../../services/position.service';
import { setPositions } from '../../features/positionSlice';
import { useGetRegionsQuery } from '../../services/region.service';
import { setRegions } from '../../features/regionSlice';
import { setDepartments } from '../../features/departmentSlice';
import { useGetDepartmentsQuery } from '../../services/department.service';

function AdminEmployeesComponent() {
  const dispatch = useAppDispatch();

  const allEmployees = useAppSelector((state) => state.employees.userEmployees);
  const allPositions = useAppSelector((state) => state.positions.positions);
  const allDepartments = useAppSelector((state) => state.departments.departments);
  const allRegions = useAppSelector((state) => state.regions.regions);

  const [filterData, setFilterData] = React.useState<FilterData>();
  const { data: employeeData } = useGetEmployeesQuery();
  const { data: positionData } = useGetPositionsQuery();
  const { data: regionData } = useGetRegionsQuery();
  const { data: departmentData } = useGetDepartmentsQuery();

  useEffect(() => {
    if (employeeData && _.get(employeeData, 'documents', []).length) {
      const employeeTmp: EmployeeData[] = employeeData.documents.filter((employee: EmployeeData) => employee.isVisible);
      dispatch(setUserEmployees([...employeeTmp]));
    } else {
      dispatch(setUserEmployees([]));
    }
  }, [employeeData]);

  useEffect(() => {
    if (positionData && _.get(positionData, 'documents', []).length) {
      dispatch(setPositions(positionData.documents));
    } else {
      dispatch(setPositions([]));
    }
  }, [positionData]);

  useEffect(() => {
    if (regionData && _.get(regionData, 'documents', []).length) {
      dispatch(setRegions(regionData.documents));
    } else {
      dispatch(setRegions([]));
    }
  }, [regionData]);

  useEffect(() => {
    if (departmentData && _.get(departmentData, 'documents', []).length) {
      dispatch(setDepartments(departmentData.documents));
    } else {
      dispatch(setDepartments([]));
    }
  }, [departmentData]);

  const handleFilter = (filterData: FilterData): void => {
    setFilterData(filterData);
  };

  const renderEmployeeList = () => {
    if (allEmployees && allEmployees.length) {
      let employees: EmployeeData[] = [...allEmployees];

      const isFiltered = filterData && !Object.values(filterData).some((x) => x !== null && x !== '');

      if (isFiltered !== undefined && filterData) {
        employees = allEmployees.filter((employee: EmployeeData) => {
          let findedByQuery = false;
          let findedByPosition = true;
          let findedBySupervisor = true;
          let findedByDepartment = true;
          let findedByRegion = true;

          // BY OPTIONAL FIELDS

          if (filterData.departmentId && employee.departmentId.toString() !== filterData.departmentId) {
            findedByPosition = false;
          }

          if (filterData.positionId && employee.positionId.toString() !== filterData.positionId) {
            findedBySupervisor = false;
          }

          if (filterData.supervisorId && employee.supervisorId.toString() !== filterData.supervisorId) {
            findedByDepartment = false;
          }

          if (filterData.regionId && employee.regionId.toString() !== filterData.regionId) {
            findedByRegion = false;
          }

          // BY QUERY VALUE
          if (filterData.query && employee.firstName.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.lastName.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.email.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (filterData.query && employee.phone.match(new RegExp(filterData.query, 'gi'))) {
            findedByQuery = true;
          }

          if (!filterData.query) {
            findedByQuery = true;
          }

          return findedByQuery && findedByPosition && findedBySupervisor && findedByDepartment && findedByRegion;
        });
      }

      if (!employees.length) {
        return (
          <tr>
            <td align="center" colSpan={11}>
              brak pracowników do wyświetlenia
            </td>
          </tr>
        );
      }

      return employees.map((employee: EmployeeData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        let positionName = '-';
        let supervisorName = '-';
        let departmentName = '-';
        let regionName = '-';

        if (employee.positionId && allPositions && allPositions.length) {
          const findedPosition: PositionData | undefined = allPositions.find((position: PositionData) => position.id === employee.positionId);
          if (findedPosition && findedPosition.name) {
            positionName = findedPosition.name;
          }
        }

        if (employee.supervisorId && allEmployees && allEmployees.length) {
          const findedEmployee: EmployeeData | undefined = allEmployees.find((supervisor: EmployeeData) => supervisor.id === employee.supervisorId);
          if (findedEmployee) {
            supervisorName = `${findedEmployee.firstName} ${findedEmployee.lastName}`;
          }
        }

        if (employee.departmentId && allDepartments && allDepartments.length) {
          const findedDepartment: DepartmentData | undefined = allDepartments.find(
            (department: DepartmentData) => department.id === employee.departmentId
          );
          if (findedDepartment) {
            departmentName = findedDepartment.name;
          }
        }

        if (employee.regionId && allRegions && allRegions.length) {
          const findedRegion: RegionData | undefined = allRegions.find((region: RegionData) => region.id === employee.regionId);
          if (findedRegion && findedRegion.name) {
            regionName = findedRegion.name;
          }
        }

        return (
          <tr className={rowClass} key={`single-employee-row-${index}`}>
            <td>{index + 1}</td>
            <td>
              <AvatarComponent imagePath={employee.photo} width={50} height={50} />
            </td>
            <td valign="middle">{employee.firstName}</td>
            <td>{employee.lastName}</td>
            <td>{positionName}</td>
            <td>{supervisorName}</td>
            <td>{departmentName}</td>
            {/* <td>{regionName}</td> */}
            <td>{employee.email}</td>
            <td>{employee.phone}</td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={11}>
          brak pracowników do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <FiltersComponent handleFilter={handleFilter} employeesType={FilterEmployeesType.USER_EMPLOYEES} />
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Zdjęcie</th>
            <th scope="col">Imię</th>
            <th scope="col">Nazwisko</th>
            <th scope="col">Stanowisko</th>
            <th scope="col">Przełożony</th>
            <th scope="col">Dział</th>
            {/* <th scope="col">Region</th> */}
            <th scope="col">E-mail</th>
            <th scope="col">Telefon</th>
          </tr>
        </thead>
        <tbody>{renderEmployeeList()}</tbody>
      </table>
    </>
  );
}

export default AdminEmployeesComponent;
