export const API_HOST = 'https://api-wms-test.kopettimakarone.usermd.net/';
// export const API_HOST = 'http://127.0.0.1:3001/';
export const AUTH_TOKEN = 'dsdseyJhbGciOiJFUzUxM';

export enum PermissionType {
  USER_TYPE_USER = 'USER_TYPE_EMPLOYEE_USER',
  USER_TYPE_ADMIN = 'USER_TYPE_EMPLOYEE_ADMIN'
}

export interface LoggedUser {
  ID: number;
  display_name: string;
  employeePermissionType: PermissionType;
  user_email: string;
  user_login: string;
  user_nicename: string;
  user_registered: string;
}

export interface LoggedUserResponse extends Response {
  loggedUser: LoggedUser;
}


export interface Response {
  success: boolean;
  errors?: string[];
}

export interface RegionData {
  id?: number;
  name: string;
}


export interface RegionResponse extends Response {
  id: string;
}

export interface RegionsResponse extends Response {
  documents: [RegionData];
}


export interface DepartmentData {
  id?: number;
  name: string;
}

export interface DepartmentResponse extends Response {
  id: string;
}

export interface DepartmentsResponse extends Response {
  documents: [DepartmentData];
}

export interface PositionData {
  id?: number;
  name: string;
}

export interface PositionResponse extends Response {
  id: string;
}

export interface PositionsResponse extends Response {
  documents: [PositionData];
}

export interface EmployeeData {
  id?: number;
  firstName: string;
  lastName: string;
  positionId: number;
  supervisorId: number;
  departmentId: number;
  regionId: number;
  email: string;
  phone: string;
  photo: string;
  isVisible: boolean;
}

export interface EmployeeResponse extends Response {
  id: string;
}

export interface EmployeesResponse extends Response {
  documents: [EmployeeData];
}

export interface FilesData { }
export interface FilesResponse extends Response {
  filePaths: string[];
  filePath?: string;
}

export interface FileData { }
export interface FileResponse extends Response { }

export interface FilterData {
  query?: string;
  positionId?: string;
  supervisorId?: string;
  departmentId?: string;
  regionId?: string;
}




