import * as React from 'react';
import { useAppSelector } from '../../store/hooks';
import SelectComponent, { SelectOption } from './select.component';
import { ReactComponent as IconClearFilter } from '../../assets/clear-filter-icon.svg';
import { DepartmentData, EmployeeData, FilterData, PositionData, RegionData } from '../../store/types';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { searchSchema, searchInitialState } from './schemas/search.schema';

export enum FilterEmployeesType {
  ADMIN_EMPLOYEES = 'adminEmployees',
  USER_EMPLOYEES = 'userEmployees'
}

interface FiltersType {
  handleFilter: (filterData: FilterData) => void;
  employeesType: FilterEmployeesType;
}

function FiltersComponent({ handleFilter, employeesType }: FiltersType) {
  const allEmployees = useAppSelector((state) => state.employees[employeesType]);
  const allPositions = useAppSelector((state) => state.positions.positions);
  const allDepartments = useAppSelector((state) => state.departments.departments);
  const allRegions = useAppSelector((state) => state.regions.regions);

  const [employeeOptions, setEmployeeOptions] = React.useState<SelectOption[]>([]);
  const [positionOptions, setPositionOptions] = React.useState<SelectOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = React.useState<SelectOption[]>([]);
  const [regionOptions, setRegionOptions] = React.useState<SelectOption[]>([]);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(searchSchema)
  });

  React.useEffect(() => {
    if (allEmployees && allEmployees.length) {
      const optionsTmp: SelectOption[] = [];

      allEmployees.map((employee: EmployeeData) => {
        if (employee.id) optionsTmp.push({ id: employee.id, value: `${employee.firstName} ${employee.lastName}` });
      });

      setEmployeeOptions(optionsTmp);
    }
  }, [allEmployees]);

  React.useEffect(() => {
    if (allPositions && allPositions.length) {
      const optionsTmp: SelectOption[] = [];

      allPositions.map((position: PositionData) => {
        if (position.id) {
          optionsTmp.push({ id: position.id, value: position.name });
        }
      });

      setPositionOptions(optionsTmp);
    }
  }, [allPositions]);

  React.useEffect(() => {
    if (allDepartments && allDepartments.length) {
      const optionsTmp: SelectOption[] = [];

      allDepartments.map((department: DepartmentData) => {
        if (department.id) {
          optionsTmp.push({ id: department.id, value: department.name });
        }
      });

      setDepartmentOptions(optionsTmp);
    }
  }, [allDepartments]);

  React.useEffect(() => {
    if (allRegions && allRegions.length) {
      const optionsTmp: SelectOption[] = [];

      allRegions.map((region: RegionData) => {
        if (region.id) {
          optionsTmp.push({ id: region.id, value: region.name });
        }
      });

      setRegionOptions(optionsTmp);
    }
  }, [allRegions]);

  const clearFilters = (): void => {
    reset({ ...searchInitialState });
    handleFilter({ ...searchInitialState });
  };

  const onSubmit = (searchData: Partial<FilterData>): void => {
    handleFilter(searchData);
  };

  return (
    <form onChange={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-5">
          <div className="form-group">
            <label htmlFor="search">Szukaj pracownika</label>
            <input type="text" className="form-control" {...register('query')} placeholder="Szukaj pracownika (imię, nazwisko lub nr tel.)" />
            {errors.query && <span className="error-message">{errors.query.message}</span>}
          </div>
        </div>
        <div className="col-2">
          <SelectComponent register={register} fieldName="positionId" label="Stanowisko" errors={errors} optionData={positionOptions} />
        </div>
        <div className="col-2">
          <SelectComponent register={register} fieldName="supervisorId" label="Przełożony" errors={errors} optionData={employeeOptions} />
        </div>
        <div className="col-2">
          <SelectComponent register={register} fieldName="departmentId" label="Dział" errors={errors} optionData={departmentOptions} />
        </div>
        {/* <div className="col-2">
          <SelectComponent register={register} fieldName="regionId" label="Region" errors={errors} optionData={regionOptions} />
        </div> */}
        <div className="col-1 d-flex align-items-center justify-content-center mt-3">
          <div className="clear-filter-icon-wrapper" onClick={clearFilters}>
            <IconClearFilter width={30} />
          </div>
        </div>
      </div>
    </form>
  );
}

export default FiltersComponent;
