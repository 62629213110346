import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { PermissionType } from '../../store/types';
import { ReactComponent as EmployeeIcon } from '../../assets/employee-icon.svg';
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg';
import { includes as _includes } from 'lodash';

function HomeComponent() {
  const [loggedUserType, setLoggedUserType] = React.useState<string>('');
  const loggedUserState = useAppSelector((state) => state.app.loggedUser);

  React.useEffect(() => {
    if (loggedUserState && loggedUserState.employeePermissionType) {
      setLoggedUserType(loggedUserState.employeePermissionType);
    }
  }, [loggedUserState]);

  return (
    <>
      {loggedUserState && (
        <div className="row app-row">
          {_includes([PermissionType.USER_TYPE_USER, PermissionType.USER_TYPE_ADMIN], loggedUserType) && (
            <div className="col-xl-6 col-sm-6 col-6">
              <Link className="app_box ease" to="/employees">
                <EmployeeIcon width={120} />
                <div className="app_box_appla"></div>
              </Link>
            </div>
          )}
          {_includes([PermissionType.USER_TYPE_ADMIN], loggedUserType) && (
            <div className="col-xl-6 col-sm-6 col-6">
              <Link className="app_box ease" to="/administration">
                <SettingsIcon width={120} />
                <div className="app_box_appla"></div>
              </Link>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HomeComponent;
