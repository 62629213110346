import * as yup from 'yup';

export const positionInitialState = {
  name: '',
};

export const positionSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required('To pole jest wymagane'),
});
