import { ReactElement } from 'react';
import { ReactComponent as IconAvatar } from '../../assets/avatar-icon.svg';
import { API_HOST } from '../../store/types';

interface AvatarType {
  imagePath?: string;
  width: number;
  height: number;
}
function AvatarComponent({ imagePath, width, height }: AvatarType) {
  const renderAvatar = (): ReactElement => {
    if (imagePath) {
      const path = `${API_HOST}${imagePath}`;
      return <img src={path} width={width} height={height} />;
    }
    return <IconAvatar width={50} />;
  };

  return (
    <div className="avatar-wrapper" style={{ width: width, height: height }}>
      {renderAvatar()}
    </div>
  );
}

export default AvatarComponent;
