import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Routing from './app/routing';
import { store } from './app/store/store';
import { Provider } from 'react-redux';
import 'react-advanced-cropper/dist/style.css';

const root = ReactDOM.createRoot(document.getElementById('my_react_app'));
root.render(
  <React.StrictMode>
    {/* <BrowserRouter basename="/employee"> */}
    <BrowserRouter>
      <Provider store={store}>
        <Routing />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
