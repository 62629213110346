import React, { useEffect } from 'react';
import { DepartmentData } from '../../store/types';
import { useAppDispatch } from '../../store/hooks';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import _ from 'lodash';
import DepartmentFormComponent from './departmentForm.component';
import { useGetDepartmentsQuery } from '../../services/department.service';
import { setDepartments, setEditedDepartment } from '../../features/departmentSlice';

function DepartmentsComponent() {
  const dispatch = useAppDispatch();

  const [query, setQuery] = React.useState<string>('');
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addDepartmentInputRef = React.useRef<any>(null);
  const { data: departmentData } = useGetDepartmentsQuery();

  useEffect(() => {
    if (departmentData && _.get(departmentData, 'documents', []).length) {
      dispatch(setDepartments(departmentData.documents));
    } else {
      dispatch(setDepartments([]));
    }
  }, [departmentData]);

  const checkIfDepartmentAlreadyExist = (departmentName: string) => {
    if (departmentData && !_.isEmpty(departmentData.documents)) {
      let allDepartments: DepartmentData[] = departmentData.documents;
      return !!allDepartments.find((department: DepartmentData) => department.name === departmentName);
    }

    return false;
  };

  const handleEditDepartment = (departmentName: string) => {
    if (departmentData && _.get(departmentData, 'documents', []).length) {
      const editedDepartment: DepartmentData | undefined = departmentData.documents.find(
        (department: DepartmentData) => department.name === departmentName
      );

      if (editedDepartment) {
        dispatch(setEditedDepartment(editedDepartment));

        if (addDepartmentInputRef.current && !isCollapsing) {
          addDepartmentInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const renderDepartmentList = () => {
    if (departmentData && !_.isEmpty(departmentData.documents)) {
      let allDepartments: DepartmentData[] = departmentData.documents;

      if (query) {
        allDepartments = allDepartments.filter((department: DepartmentData) => {
          let finded = false;

          if (department.name.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          return finded;
        });
      }

      return allDepartments.map((department: DepartmentData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
        return (
          <tr className={rowClass} key={`single-region-row-${index}`}>
            <td>{index + 1}</td>
            <td>{department.name}</td>
            <td>
              <div onClick={() => handleEditDepartment(department.name)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={3}>
          brak działów do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <DepartmentFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addDepartmentInputRef={addDepartmentInputRef}
        checkIfDepartmentAlreadyExist={checkIfDepartmentAlreadyExist}
      />
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="search">Szukaj działu</label>
            <input type="text" className="form-control" onChange={(e) => setQuery(e.target.value)} placeholder="Podaj frazę której szukasz" />
          </div>
        </div>
      </div>

      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nazwa działu</th>
            <th scope="col" style={{ width: '50px' }}>
              Akcja
            </th>
          </tr>
        </thead>
        <tbody>{renderDepartmentList()}</tbody>
      </table>
    </>
  );
}

export default DepartmentsComponent;
