import * as yup from 'yup';

export const employeeInitialState = {
  id: null,
  firstName: '',
  lastName: '',
  positionId: '',
  supervisorId: '',
  departmentId: '',
  regionId: '',
  email: '',
  phone: '',
  photo: '',
  isVisible: true,
};

export const employeeSchema = yup.object().shape({
  id: yup.number().nullable(),
  firstName: yup.string().required('Imię jest wymagane'),
  lastName: yup.string().required('Nazwisko jest wymagane'),
  positionId: yup.string().required('Wybierz stanowisko'),
  supervisorId: yup.string(),
  departmentId: yup.string().required('Wybierz dział'),
  // regionId: yup.string().required('Wybierz region'),
  regionId: yup.string(),
  email: yup.string().email('Wpisz poparwny adres e-mail'),
  phone: yup.string().required('Telefon jest wymagany'),
  isVisible: yup.boolean(),
  photo: yup.string(),
});
