import * as React from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../features/appSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { departmentInitialState, departmentSchema } from './schemas/department.schema';
import { DepartmentData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddDepartmentMutation, useEditDepartmentMutation } from '../../services/department.service';
import { resetEditedDepartment } from '../../features/departmentSlice';

interface DepartmentFormType {
  addDepartmentInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfDepartmentAlreadyExist: (departmentName: string) => boolean;
}

function DepartmentFormComponent({ addDepartmentInputRef, isCollapsing, setIsCollapsing, checkIfDepartmentAlreadyExist }: DepartmentFormType) {
  const editedDepartmentState = useAppSelector((state) => state.departments.editedDepartment);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(departmentSchema)
  });
  const dispatch = useAppDispatch();
  const [addDepartment] = useAddDepartmentMutation();
  const [editDepartment] = useEditDepartmentMutation();

  const id = getValues('id');

  React.useEffect(() => {
    if (editedDepartmentState) {
      reset({ ...editedDepartmentState });
    } else {
      reset({ ...departmentInitialState });
    }
  }, [editedDepartmentState]);

  const onSubmit = handleSubmit(async (data: Partial<DepartmentData>) => {
    if (
      data &&
      data.name &&
      (!editedDepartmentState || (editedDepartmentState && editedDepartmentState.name !== data.name)) &&
      checkIfDepartmentAlreadyExist(data.name.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Dział tej nazwie ${data.name} już istnieje na liście działów`
        })
      );
      return;
    }

    const response = await (id ? editDepartment(data) : addDepartment(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Dział został zaktualizowany' : 'Dodano dział') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add department form
      if (addDepartmentInputRef.current) {
        addDepartmentInputRef.current.click();
      }

      dispatch(resetEditedDepartment());
      reset({ ...departmentInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedDepartment());
      reset({ ...departmentInitialState });
    }

    if (addDepartmentInputRef.current) {
      addDepartmentInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addDepartmentInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-department-collapse"
          aria-expanded="false"
          aria-controls="add-department-collapse"
          className={`add-department-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedDepartmentState ? `Edytuj dział ${editedDepartmentState.name}` : 'Dodaj dział'}
        </div>
      </div>
      <div className="collapse employee-collapse" id="add-department-collapse">
        <div className="card card-body">
          <p>{editedDepartmentState ? `Edytuj dział ${editedDepartmentState.name}` : 'Dodaj dział'}</p>
          <form id="addNewDepartment" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">Nazwa</label>
                  <input {...register('name')} type="text" className="form-control" placeholder="podaj nazwę działu" />
                  {errors.name && <span className="error-message">{errors.name.message}</span>}
                </div>
              </div>
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj dział' : 'Dodaj dział'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default DepartmentFormComponent;
