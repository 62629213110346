import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DepartmentData } from '../store/types';

interface DepartmentState {
  editedDepartment: DepartmentData | null;
  departments: DepartmentData[];
}

const initialState: DepartmentState = {
  editedDepartment: null,
  departments: []
};

export const departmentSlice = createSlice({
  name: 'departments',
  initialState,
  reducers: {
    setEditedDepartment: (state, action: PayloadAction<DepartmentData | null>) => {
      state.editedDepartment = action.payload;
    },
    resetEditedDepartment: (state) => {
      state.editedDepartment = null;
    },
    setDepartments: (state, action: PayloadAction<DepartmentData[]>) => {
      state.departments = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDepartments, setEditedDepartment, resetEditedDepartment } = departmentSlice.actions;

export default departmentSlice.reducer;
