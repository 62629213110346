import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoggedUser, PermissionType } from '../store/types';

interface AppState {
  toast: {
    show: Boolean;
    type: string;
    message: string;
  };
  popup: {
    show: Boolean;
    message: string;
  };
  userID: string;
  loggedUser: {
    ID: number;
    display_name: string;
    employeePermissionType: PermissionType;
    user_email: string;
    user_login: string;
    user_nicename: string;
    user_registered: string;
  } | null;
}

const initialState: AppState = {
  toast: {
    show: false,
    type: '',
    message: ''
  },
  popup: {
    show: false,
    message: ''
  },
  userID: '',
  loggedUser: null
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    showToast: (state, action: PayloadAction<{ show: boolean; type: string; message: string }>) => {
      state.toast = action.payload;
    },
    showPopup: (state, action: PayloadAction<{ show: boolean; message: string }>) => {
      state.popup = action.payload;
    },
    setUserID: (state, action: PayloadAction<string>) => {
      state.userID = action.payload;
    },
    setLoggedUser: (state, action: PayloadAction<LoggedUser>) => {
      state.loggedUser = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { showToast, showPopup, setUserID, setLoggedUser } = appSlice.actions;

export default appSlice.reducer;
