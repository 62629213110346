import React, { useEffect } from 'react';
import { PositionData } from '../../store/types';
import { useAppDispatch } from '../../store/hooks';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { useGetPositionsQuery } from '../../services/position.service';
import { setEditedPosition, setPositions } from '../../features/positionSlice';
import PositionFormComponent from './positionForm.component';
import _ from 'lodash';

function PositionsComponent() {
  const dispatch = useAppDispatch();

  const [query, setQuery] = React.useState<string>('');
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addPositionInputRef = React.useRef<any>(null);
  const { data: positionData } = useGetPositionsQuery();

  useEffect(() => {
    if (positionData && _.get(positionData, 'documents', []).length) {
      dispatch(setPositions(positionData.documents));
    } else {
      dispatch(setPositions([]));
    }
  }, [positionData]);

  const checkIfPositionAlreadyExist = (positionName: string) => {
    if (positionData && !_.isEmpty(positionData.documents)) {
      let allPositions: PositionData[] = positionData.documents;
      return !!allPositions.find((position: PositionData) => position.name === positionName);
    }

    return false;
  };

  const handleEditPosition = (positionName: string) => {
    if (positionData && _.get(positionData, 'documents', []).length) {
      const editedPosition: PositionData | undefined = positionData.documents.find((position: PositionData) => position.name === positionName);

      if (editedPosition) {
        dispatch(setEditedPosition(editedPosition));

        if (addPositionInputRef.current && !isCollapsing) {
          addPositionInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const renderPositionList = () => {
    if (positionData && !_.isEmpty(positionData.documents)) {
      let allPositions: PositionData[] = positionData.documents;

      if (query) {
        allPositions = allPositions.filter((position: PositionData) => {
          let finded = false;

          if (position.name.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          return finded;
        });
      }

      return allPositions.map((position: PositionData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
        return (
          <tr className={rowClass} key={`single-position-row-${index}`}>
            <td>{index + 1}</td>
            <td>{position.name}</td>
            <td>
              <div onClick={() => handleEditPosition(position.name)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={3}>
          brak pozycji do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <PositionFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addPositionInputRef={addPositionInputRef}
        checkIfPositionAlreadyExist={checkIfPositionAlreadyExist}
      />
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="search">Szukaj stanowiska</label>
            <input type="text" className="form-control" onChange={(e) => setQuery(e.target.value)} placeholder="Podaj frazę której szukasz" />
          </div>
        </div>
      </div>

      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nazwa stanowiska</th>
            <th scope="col" style={{ width: '50px' }}>
              Akcja
            </th>
          </tr>
        </thead>
        <tbody>{renderPositionList()}</tbody>
      </table>
    </>
  );
}

export default PositionsComponent;
