import * as React from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../features/appSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { positionInitialState, positionSchema } from './schemas/position.schema';
import { PositionData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddPositionMutation, useEditPositionMutation } from '../../services/position.service';
import { resetEditedPosition } from '../../features/positionSlice';

interface PositionFormType {
  addPositionInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfPositionAlreadyExist: (positionName: string) => boolean;
}

function PositionFormComponent({ addPositionInputRef, isCollapsing, setIsCollapsing, checkIfPositionAlreadyExist }: PositionFormType) {
  const editedPositionState = useAppSelector((state) => state.positions.editedPosition);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(positionSchema)
  });
  const dispatch = useAppDispatch();
  const [addPosition] = useAddPositionMutation();
  const [editPosition] = useEditPositionMutation();

  const id = getValues('id');

  React.useEffect(() => {
    if (editedPositionState) {
      reset({ ...editedPositionState });
    } else {
      reset({ ...positionInitialState });
    }
  }, [editedPositionState]);

  const onSubmit = handleSubmit(async (data: Partial<PositionData>) => {
    if (
      data &&
      data.name &&
      (!positionInitialState || (editedPositionState && editedPositionState.name !== data.name)) &&
      checkIfPositionAlreadyExist(data.name.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Stanowisko o tej nazwie ${data.name} już istnieje na liście stanowisk`
        })
      );
      return;
    }

    const response = await (id ? editPosition(data) : addPosition(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Stanowisko zostało zaktualizowane' : 'Dodano stanowisko') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add region form
      if (addPositionInputRef.current) {
        addPositionInputRef.current.click();
      }

      dispatch(resetEditedPosition());
      reset({ ...positionInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedPosition());
      reset({ ...positionInitialState });
    }

    if (addPositionInputRef.current) {
      addPositionInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addPositionInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-position-collapse"
          aria-expanded="false"
          aria-controls="add-position-collapse"
          className={`add-position-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedPositionState ? `Edytuj stanowisko ${editedPositionState.name}` : 'Dodaj stanowisko'}
        </div>
      </div>
      <div className="collapse employee-collapse" id="add-position-collapse">
        <div className="card card-body">
          <p>{editedPositionState ? `Edytuj stanowisko ${editedPositionState.name}` : 'Dodaj stanowisko'}</p>
          <form id="addNewPosition" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="name">Nazwa</label>
                  <input {...register('name')} type="text" className="form-control" placeholder="podaj nazwę stanowiska" />
                  {errors.name && <span className="error-message">{errors.name.message}</span>}
                </div>
              </div>
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj stanowisko' : 'Dodaj stanowisko'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default PositionFormComponent;
