import * as yup from 'yup';

export const departmentInitialState = {
  name: '',
};

export const departmentSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required('To pole jest wymagane'),
});
