import * as React from 'react';
import { useForm } from 'react-hook-form';
import { showToast } from '../../features/appSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { employeeInitialState, employeeSchema } from './schemas/employee.schema';
import { DepartmentData, EmployeeData, PositionData, RegionData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddEmployeeMutation, useEditEmployeeMutation } from '../../services/employee.service';
import { resetEditedEmployee } from '../../features/employeeSlice';
import SelectComponent, { SelectOption } from '../application/select.component';
import CropperComponent from '../application/cropper.component';
import AvatarComponent from '../application/avatar.component';
import { ReactComponent as IconRemove } from '../../assets/reject-icon.svg';

interface EmployeeFormType {
  addEmployeeInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfEmployeeAlreadyExist: (employeeName: string) => boolean;
}

function EmployeeFormComponent({ addEmployeeInputRef, isCollapsing, setIsCollapsing, checkIfEmployeeAlreadyExist }: EmployeeFormType) {
  const editedEmployeeState = useAppSelector((state) => state.employees.editedEmployee);
  const allEmployees = useAppSelector((state) => state.employees.adminEmployees);
  const allPositions = useAppSelector((state) => state.positions.positions);
  const allDepartments = useAppSelector((state) => state.departments.departments);
  const allRegions = useAppSelector((state) => state.regions.regions);

  const [employeeOptions, setEmployeeOptions] = React.useState<SelectOption[]>([]);
  const [positionOptions, setPositionOptions] = React.useState<SelectOption[]>([]);
  const [departmentOptions, setDepartmentOptions] = React.useState<SelectOption[]>([]);
  const [regionOptions, setRegionOptions] = React.useState<SelectOption[]>([]);

  React.useEffect(() => {
    if (allEmployees && allEmployees.length) {
      const optionsTmp: SelectOption[] = [];

      allEmployees.map((employee: EmployeeData) => {
        if (employee.id) {
          optionsTmp.push({ id: employee.id, value: `${employee.firstName} ${employee.lastName}` });
        }
      });

      setEmployeeOptions(optionsTmp);
    }
  }, [allEmployees]);

  React.useEffect(() => {
    if (allPositions && allPositions.length) {
      const optionsTmp: SelectOption[] = [];

      allPositions.map((position: PositionData) => {
        if (position.id) {
          optionsTmp.push({ id: position.id, value: position.name });
        }
      });

      setPositionOptions(optionsTmp);
    }
  }, [allPositions]);

  React.useEffect(() => {
    if (allDepartments && allDepartments.length) {
      const optionsTmp: SelectOption[] = [];

      allDepartments.map((department: DepartmentData) => {
        if (department.id) {
          optionsTmp.push({ id: department.id, value: department.name });
        }
      });

      setDepartmentOptions(optionsTmp);
    }
  }, [allDepartments]);

  React.useEffect(() => {
    if (allRegions && allRegions.length) {
      const optionsTmp: SelectOption[] = [];

      allRegions.map((region: RegionData) => {
        if (region.id) {
          optionsTmp.push({ id: region.id, value: region.name });
        }
      });

      setRegionOptions(optionsTmp);
    }
  }, [allRegions]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(employeeSchema)
  });
  const dispatch = useAppDispatch();
  const [addEmployee] = useAddEmployeeMutation();
  const [editEmployee] = useEditEmployeeMutation();

  const id = getValues('id');
  const photo = watch('photo', false);

  React.useEffect(() => {
    if (editedEmployeeState) {
      reset({ ...editedEmployeeState });
    } else {
      reset({ ...employeeInitialState });
    }
  }, [editedEmployeeState]);

  const onSubmit = handleSubmit(async (data: Partial<EmployeeData>) => {
    if (
      data &&
      data.firstName &&
      data.lastName &&
      (!employeeInitialState || (employeeInitialState && employeeInitialState.id && employeeInitialState.id !== data.id)) &&
      checkIfEmployeeAlreadyExist(`${data.firstName}  ${data.lastName}}`.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Pracownik ${data.firstName}  ${data.lastName} już istnieje na liście pracowników`
        })
      );
      return;
    }

    const response = await (id ? editEmployee(data) : addEmployee(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Pracownik został zaktualizowany' : 'Dodano pracownika') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add region form
      if (addEmployeeInputRef.current) {
        addEmployeeInputRef.current.click();
      }

      dispatch(resetEditedEmployee());
      reset({ ...employeeInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedEmployee());
      reset({ ...employeeInitialState });
    }

    if (addEmployeeInputRef.current) {
      addEmployeeInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addEmployeeInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-employee-collapse"
          aria-expanded="false"
          aria-controls="add-employee-collapse"
          className={`add-employee-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedEmployeeState ? `Edytuj pracownika ${editedEmployeeState.firstName} ${editedEmployeeState.lastName}` : 'Dodaj pracownika'}
        </div>
      </div>
      <div className="collapse employee-collapse" id="add-employee-collapse">
        <div className="card card-body">
          <p>{editedEmployeeState ? `Edytuj pracownika ${editedEmployeeState.firstName} ${editedEmployeeState.lastName}` : 'Dodaj pracownika'}</p>
          <form id="addNewEmployee" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="firstName">Imię</label>
                  <input {...register('firstName')} type="text" className="form-control" placeholder="podaj imię pracownika" />
                  {errors.firstName && <span className="error-message">{errors.firstName.message}</span>}
                </div>
              </div>
              <div className="col-5">
                <div className="form-group">
                  <label htmlFor="lastName">Nazwisko</label>
                  <input {...register('lastName')} type="text" className="form-control" placeholder="podaj nazwisko pracownika" />
                  {errors.lastName && <span className="error-message">{errors.lastName.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <input {...register('email')} type="text" className="form-control" placeholder="podaj e-mail pracownika" />
                  {errors.email && <span className="error-message">{errors.email.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="phone">Nr. telefonu</label>
                  <input {...register('phone')} type="number" className="form-control" placeholder="podaj telefon pracownika" />
                  {errors.phone && <span className="error-message">{errors.phone.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <SelectComponent register={register} fieldName="positionId" label="Stanowisko" errors={errors} optionData={positionOptions} />
              </div>
              <div className="col-3">
                <SelectComponent register={register} fieldName="supervisorId" label="Przełożony" errors={errors} optionData={employeeOptions} />
              </div>
              <div className="col-3">
                <SelectComponent register={register} fieldName="departmentId" label="Dział" errors={errors} optionData={departmentOptions} />
              </div>
              {/* <div className="col-3">
                <SelectComponent register={register} fieldName="regionId" label="Region" errors={errors} optionData={regionOptions} />
              </div> */}
              <div className="col-12">
                <div className="form-group form-check  d-flex flex-row align-items-center">
                  <input {...register('isVisible')} type="checkbox" className="form-check-input" id="employeeIsVisible" />
                  <label className="form-check-label mt-1" htmlFor="employeeIsVisible">
                    Użytkownik aktywny
                  </label>
                </div>
              </div>
              <div className="col-12">
                <div className="image-form-wrapper mt-3 d-flex flex-column align-items-center">
                  {photo && (
                    <div className="form-avatar">
                      <AvatarComponent imagePath={photo} width={100} height={100} />
                      <div className="form-avatar-remove-icon" onClick={() => setValue('photo', '')}>
                        <IconRemove width={24} />
                      </div>
                    </div>
                  )}
                  <CropperComponent setValue={setValue} fieldName="photo" />
                </div>
              </div>
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj pracownika' : 'Dodaj pracownika'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default EmployeeFormComponent;
